import { getCookies } from 'cookies-next';
import dynamic from 'next/dynamic';

import useExperimentVariant from 'hooks/useExperimentVariant';

import { getServerExperimentProps } from 'lib/experiment';
import { resolvify } from 'lib/promises';
import { parseToBool, removeWhitespaceBetweenCharacters } from 'lib/string';
import { getFeaturedLanding } from 'lib/utils';

import { getAds } from 'services/ads';
import { getCategoriesList } from 'services/category';
import { getLandingsList } from 'services/landing';
import { getFeaturedOffersList, getOffersList } from 'services/offer';
import { getStoresList } from 'services/store';
import { getPasswordRecoveryHashValidation } from 'services/user';

import APP from 'constants/app';
import COOKIES from 'constants/cookies';
import LINKS from 'constants/links';

const HomePageA = dynamic(() => import('components/pages/Home'));
const HomePageB = dynamic(() => import('components/pages/Home/B'));

const OFFERS_SERVER_LIMIT = 12;
const TAB_NAME = 'highlights';

const Index = ({
  cardsAds,
  cookieIsUserLogged,
  cookieOffersViewMode,
  recoveryPassword,
  serverFeaturedOffers,
  serverOffers,
  sidebarAd,
  smallAds,
}) => {
  const [showRedesignedTimeline] = useExperimentVariant(
    '00018',
    [false, true, true],
    {
      fallback: { outOfDateValue: false },
    }
  );

  if (showRedesignedTimeline) {
    return (
      <HomePageB
        cardsAds={cardsAds}
        cookieIsUserLogged={cookieIsUserLogged}
        cookieOffersViewMode={cookieOffersViewMode}
        recoveryPassword={recoveryPassword}
        serverFeaturedOffers={serverFeaturedOffers}
        serverOffers={serverOffers}
        smallAds={smallAds}
      />
    );
  }

  return (
    <HomePageA
      cardsAds={cardsAds}
      cookieIsUserLogged={cookieIsUserLogged}
      cookieOffersViewMode={cookieOffersViewMode}
      recoveryPassword={recoveryPassword}
      serverFeaturedOffers={serverFeaturedOffers}
      serverOffers={serverOffers}
      sidebarAd={sidebarAd}
      smallAds={smallAds}
    />
  );
};

export const getServerSideProps = async ({ query, req, res }) => {
  const {
    ha: recoveryHash = null,
    em: rawUserEmail = null,
    pass_rec: isPasswordRecoveryMode,
  } = query;
  /**
   * In some edge cases the recovery email could contain the "+" character like
   * "example+1@test.com" and it is returned as "example 1@test.com" which
   * is not a valid email. We deal with it below
   */
  const userEmail = rawUserEmail
    ? removeWhitespaceBetweenCharacters(rawUserEmail.trim())
    : null;

  try {
    const [
      serverOffers,
      serverFeaturedOffers,
      categories,
      stores,
      { landings: featuredLandings },
      { landings: highlightedLandings },
      {
        data: {
          cardAd: cardsAds = [],
          smallAd: smallAds = [],
          sidebarAd: [sidebarAd = null],
          topAd: [topbarAd = null],
        },
      },
      passwordRecovery,
    ] = await resolvify([
      getOffersList({ limit: OFFERS_SERVER_LIMIT, ...query }),
      { fn: getFeaturedOffersList(), fallback: getFeaturedOffersList.fallback },
      {
        fn: getCategoriesList({ selectedCategories: true }),
        fallback: getCategoriesList.fallback,
      },
      {
        fn: getStoresList({ from: 'topbar' }),
        fallback: getStoresList.fallback,
      },
      {
        fn: getLandingsList({ featured: true }),
        fallback: getLandingsList.fallback,
      },
      {
        fn: getLandingsList({ highlighted: true }),
        fallback: getLandingsList.fallback,
      },
      {
        fn: getAds(),
        fallback: getAds.fallback,
      },
      isPasswordRecoveryMode && recoveryHash && userEmail
        ? {
            fn: getPasswordRecoveryHashValidation({
              recoveryHash,
              userEmail,
            }),
            fallback: getPasswordRecoveryHashValidation.fallback,
          }
        : null,
    ]);

    let recoveryPassword = null;

    if (passwordRecovery && passwordRecovery.isHashValid) {
      recoveryPassword = {
        recoveryHash,
        userEmail,
        isValid: true,
      };
    }

    const {
      [COOKIES.IS_USER_LOGGED]: cookieIsUserLogged = false,
      [COOKIES.OFFERS_VIEW_MODE]: cookieOffersViewMode = null,
    } = getCookies({ req, res });

    return {
      props: {
        cardsAds,
        cookieIsUserLogged: parseToBool(cookieIsUserLogged),
        cookieOffersViewMode,
        recoveryPassword,
        serverFeaturedOffers,
        serverOffers,
        sidebarAd,
        smallAds,
        [APP.CTX_OPTIONAL_PROPS]: {
          activeOffersListTab: TAB_NAME,
          categories,
          experiments: {
            ...getServerExperimentProps({
              expId: '00018',
              req,
              res,
            }),
          },
          featuredLanding: getFeaturedLanding(featuredLandings),
          highlightedLandings,
          stores,
          topbarAd,
        },
      },
    };
  } catch {
    return { redirect: { destination: LINKS[500], permanent: false } };
  }
};

export default Index;
