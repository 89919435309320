import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import { twJoin } from 'tailwind-merge';

import { noop } from 'lib/utils';

import Icon from '../Icon';
import Spinner from '../Spinner';

const IconRight = ({
  className,
  error,
  icon,
  loading,
  success,
  onClick = noop,
}) => {
  if (loading) {
    return (
      <div className={className}>
        <Spinner size="size2" />
      </div>
    );
  }

  if (error) {
    return (
      <Icon
        className={twJoin('text-error-300 dark:text-error-200', className)}
        name="error-circle"
        size="size2"
      />
    );
  }

  if (success) {
    return (
      <Icon
        className={twJoin('text-success-300 dark:text-success-300', className)}
        name="check-circle"
        size="size2"
      />
    );
  }

  if (!onClick && !icon.props.onClick) {
    return cloneElement(icon, { size: 'size2', className });
  }

  return (
    <button
      className={className}
      onClick={(event) => {
        if (icon.props.onClick) {
          icon.props.onClick(event);
        }

        if (onClick) {
          onClick(event);
        }
      }}
    >
      {cloneElement(icon, { size: 'size2' })}
    </button>
  );
};

IconRight.propTypes = {
  error: PropTypes.bool,
  icon: PropTypes.shape(),
  loading: PropTypes.bool,
  success: PropTypes.bool,
  onClick: PropTypes.func,
};

export default IconRight;
