/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: adequate component to a11y
import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import { twMerge } from 'tailwind-merge';

import { noop } from 'lib/utils';

const SIZES = ['size1', 'size2'];
const TYPES = ['primary', 'secondary', 'neutral', 'highlight', 'success'];

const toSizeMeta = (size) => {
  const iconSizesMap = {
    size1: {
      size: 'size2',
      ml: 'ml-2',
      mr: 'mr-2',
    },
    size2: {
      size: 'size3',
      ml: 'ml-1',
      mr: 'mr-1',
    },
  };

  return size && iconSizesMap[size];
};

const sizesStyles = {
  size1: 'py-1 px-4 text-base',
  size2: 'py-0.5 px-2 lg:py-1 lg:px-2 text-xs',
};

const typesStyles = {
  primary:
    'bg-transparent border-primary-300 text-primary-300 [&>svg]:text-primary-300 dark:[&>svg]:text-primary-300',
  secondary:
    'bg-transparent border-secondary-300 text-secondary-300 [&>svg]:text-secondary-300 dark:[&>svg]:text-secondary-300',
  neutral:
    'bg-transparent border-neutral-low-200 text-neutral-low-200 [&>svg]:text-neutral-low-200 dark:border-neutral-high-400 dark:text-neutral-high-400 dark:[&>svg]:text-neutral-high-400',
  highlight:
    'bg-transparent border-highlight-300 text-highlight-300 [&>svg]:text-highlight-300 dark:[&>svg]:text-highlight-300',
  success:
    'bg-transparent border-success-300 text-success-300 [&>svg]:text-success-300 dark:[&>svg]:text-success-300',
};

const typesHoverStyles = {
  primary:
    'hover:border-primary-400 hover:text-primary-400 [&>svg]:hover:text-primary-400 dark:[&>svg]:hover:text-primary-400',
  secondary:
    'hover:border-secondary-400 hover:text-secondary-400 [&>svg]:hover:text-secondary-400 dark:[&>svg]:hover:text-secondary-400',
  neutral:
    'hover:border-neutral-low-400 hover:text-neutral-low-400 [&>svg]:hover:border-neutral-low-400 dark:[&>svg]:hover:text-neutral-high-200 dark:hover:text-neutral-high-200 dark:hover:border-neutral-high-200',
  highlight:
    'hover:border-highlight-400 hover:text-highlight-400 [&>svg]:hover:text-highlight-400 dark:[&>svg]:hover:text-highlight-400',
  success:
    'hover:border-success-400 hover:text-success-400 [&>svg]:hover:text-success-400 dark:[&>svg]:hover:text-success-400',
};

const typesFilledStyles = {
  primary:
    'bg-primary-100 border-primary-100 text-primary-400 [&>svg]:text-primary-400',
  secondary:
    'bg-secondary-100 border-secondary-100 text-secondary-400 [&>svg]:text-secondary-400',
  neutral:
    'bg-neutral-high-300 border-neutral-high-300 text-neutral-low-200 [&>svg]:text-neutral-low-200 dark:bg-neutral-low-300 dark:border-neutral-low-300 dark:text-neutral-high-400 dark:[&>svg]:text-neutral-high-400',
  highlight:
    'bg-highlight-100 border-highlight-100 text-highlight-400 [&>svg]:text-highlight-400',
  success:
    'bg-success-100 border-success-100 text-success-400 [&>svg]:text-success-400',
};

const Tag = ({
  children = null,
  className,
  clickable = false,
  disabled = false,
  filled = false,
  iconLeft = null,
  iconRight = null,
  size = 'size2',
  type = 'neutral',
  onClick = noop,
  ...rest
}) => {
  const onClickHandler = (e) => {
    if (!clickable) {
      return;
    }

    onClick(e);
  };

  return (
    <div
      className={twMerge(
        'flex h-fit w-fit select-none items-center justify-center whitespace-pre rounded-4 border border-solid font-regular',
        sizesStyles[size],
        typesStyles[type],
        clickable && !disabled && typesHoverStyles[type],
        clickable && 'cursor-pointer transition-all duration-150 ease-in',
        filled && typesFilledStyles[type],
        disabled &&
          'cursor-not-allowed border-transparent bg-neutral-high-200 text-neutral-high-400 [&>svg]:text-neutral-high-400',
        className
      )}
      onClick={onClickHandler}
      {...rest}
    >
      {iconLeft &&
        cloneElement(iconLeft, {
          size: toSizeMeta(size).size,
          className: toSizeMeta(size).mr,
        })}
      {children}
      {iconRight &&
        cloneElement(iconRight, {
          size: toSizeMeta(size).size,
          className: toSizeMeta(size).ml,
        })}
    </div>
  );
};

Tag.propTypes = {
  /**
   * String or `Icon` to be rendered inside component
   */
  children: PropTypes.node,
  /**
   * Renders a `Tag` with clickable behavior
   */
  clickable: PropTypes.bool,
  /**
   * Renders a disabled `Tag`
   */
  disabled: PropTypes.bool,
  /**
   * Renders a `Tag` with filled styles
   */
  filled: PropTypes.bool,
  /**
   * Renders an icon on the left side
   */
  iconLeft: PropTypes.object,
  /**
   * Renders an icon on the right side
   */
  iconRight: PropTypes.object,
  /**
   * Renders a `Tag` with one of the following sizes
   */
  size: PropTypes.oneOf(SIZES),
  /**
   * Renders a `Tag` with different types
   */
  type: PropTypes.oneOf(TYPES),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on click
   */
  onClick: PropTypes.func,
};

export default Tag;
