import PropTypes from 'prop-types';
import { Fragment, memo, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import Tag from 'shopper/components/Tag';
import Text from 'shopper/components/Text';

const MAX_CHARACTER_LENGTH_ON_OFFER_TAGS_COMBINED = 27;

const OfferCardTags = ({
  className,
  limitOffersTagsByOfferCardSize = true,
  offerTags = [],
  ...rest
}) => {
  const tags = useMemo(
    () =>
      limitOffersTagsByOfferCardSize
        ? offerTags.reduce((acc, { name, type }, index) => {
            const namesStringLength = acc.reduce(
              (a, b) => a + b.name.length,
              0
            );

            if (
              index === 0 ||
              namesStringLength + name.length <
                MAX_CHARACTER_LENGTH_ON_OFFER_TAGS_COMBINED
            ) {
              acc.push({ name, type });
            }

            return acc;
          }, [])
        : offerTags,
    [offerTags, limitOffersTagsByOfferCardSize]
  );

  const tagNameTotalStringLength = useMemo(
    () => tags.reduce((a, b) => a + b.name.length, 0),
    [tags]
  );

  if (offerTags.length === 0) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'flex flex-nowrap content-start overflow-x-hidden',
        className
      )}
      {...rest}
    >
      {tags.map(({ name }) => (
        <Fragment key={name}>
          <Tag
            className="mr-1 whitespace-pre px-2 py-1 last:mr-0 md:mb-2"
            filled
          >
            {name}
          </Tag>
        </Fragment>
      ))}
      {tagNameTotalStringLength !==
        MAX_CHARACTER_LENGTH_ON_OFFER_TAGS_COMBINED &&
        offerTags.length !== tags.length && (
          <Text as="span" className="ml-1" size="size2">
            ...
          </Text>
        )}
    </div>
  );
};

OfferCardTags.propTypes = {
  offerTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  limitOffersTagsByOfferCardSize: PropTypes.bool,
};

export default memo(OfferCardTags);
