import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import useModal from 'hooks/useModal';

import { sendEvent } from 'lib/gtag';

import { useUser } from 'providers/UserProvider';

import OffersOptions from './OffersOptions';

import OFFER from 'constants/offer';

const FilterOffersByCategoriesModal = dynamic(
  () => import('components/Timeline/FilterOffersByCategoriesDialog'),
  { ssr: false }
);

const OffersListHeader = forwardRef(
  (
    { gaEventCategory, offersViewMode, onOffersViewMode = null, ...rest },
    ref
  ) => {
    const { showModal } = useModal();
    const { runCallbackIfLoggedIn } = useUser();

    const onHideOffersCategoriesLinkClick = (e) => {
      e.preventDefault();

      runCallbackIfLoggedIn(() => {
        sendEvent({
          action: 'show_hide_categories',
          category: gaEventCategory,
        });

        showModal(FilterOffersByCategoriesModal);
      });
    };

    return (
      <div {...rest}>
        <OffersOptions
          ref={ref}
          gaEventCategory={gaEventCategory}
          offersViewMode={offersViewMode}
          onHideOffersLinkClick={onHideOffersCategoriesLinkClick}
          onOffersGridViewButtonClick={() => {
            runCallbackIfLoggedIn(onOffersViewMode, {
              mode: OFFER.VIEW_MODES.GRID,
            });
          }}
          onOffersListViewButtonClick={() => {
            runCallbackIfLoggedIn(onOffersViewMode, {
              mode: OFFER.VIEW_MODES.LIST,
            });
          }}
        />
      </div>
    );
  }
);

OffersListHeader.displayName = 'OffersListHeader';

OffersListHeader.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
  offersViewMode: PropTypes.string,
  onOffersViewMode: PropTypes.func.isRequired,
};

export default OffersListHeader;
